<template>
    <a
        :href="url"
        :class="{
            [$style['link']]: true,
            [$style['link_gmail']]: icon === 'gmail',
            [$style['link_slack']]: icon === 'slack',
            [$style['link_roundings_none']]: $simlaweb.settings.roundings === ROUNDINGS.NONE,
            [$style['link_roundings_small']]: $simlaweb.settings.roundings === ROUNDINGS.SMALL,
            [$style['link_roundings_large']]: $simlaweb.settings.roundings === ROUNDINGS.LARGE,
        }"
        target="_blank"
        rel="nofollow noopener noreferrer"
    >
        <SvgSpriteFacebook v-if="icon === 'fb'" />
        <SvgSpriteGmail v-else-if="icon === 'gmail'" />
        <SvgSpriteInstagram v-else-if="icon === 'insta'" />
        <SvgSpriteOk v-else-if="icon === 'ok'" />
        <SvgSpriteSlack v-else-if="icon === 'slack'" />
        <SvgSpriteSkype v-else-if="icon === 'skype'" />
        <SvgSpriteTelegram v-else-if="icon === 'telegram'" />
        <SvgSpriteTwitter v-else-if="icon === 'twitter'" />
        <SvgSpriteViber v-else-if="icon === 'viber'" />
        <SvgSpriteVk v-else-if="icon === 'vk'" />
        <SvgSpriteWechat v-else-if="icon === 'wechat'" />
        <SvgSpriteWhatsapp v-else-if="icon === 'whatsapp'" />
        <SvgSpriteYoutube v-else-if="icon === 'youtube'" />
    </a>
</template>

<script>
import SvgSpriteFacebook from './SocialNetwork/sprite-facebook.svg'
import SvgSpriteGmail from './SocialNetwork/sprite-gmail.svg'
import SvgSpriteInstagram from './SocialNetwork/sprite-instagram.svg'
import SvgSpriteOk from './SocialNetwork/sprite-ok.svg'
import SvgSpriteSkype from './SocialNetwork/sprite-skype.svg'
import SvgSpriteSlack from './SocialNetwork/sprite-slack.svg'
import SvgSpriteTelegram from './SocialNetwork/sprite-telegram.svg'
import SvgSpriteTwitter from './SocialNetwork/sprite-twitter.svg'
import SvgSpriteViber from './SocialNetwork/sprite-viber.svg'
import SvgSpriteVk from './SocialNetwork/sprite-vk.svg'
import SvgSpriteWechat from './SocialNetwork/sprite-wechat.svg'
import SvgSpriteWhatsapp from './SocialNetwork/sprite-whatsapp.svg'
import SvgSpriteYoutube from './SocialNetwork/sprite-youtube.svg'

import { ROUNDINGS } from '@@/framework/types'

export default {
  name: 'SocialNetworkLink',

  components: {
    SvgSpriteFacebook,
    SvgSpriteGmail,
    SvgSpriteInstagram,
    SvgSpriteOk,
    SvgSpriteSkype,
    SvgSpriteSlack,
    SvgSpriteTelegram,
    SvgSpriteTwitter,
    SvgSpriteViber,
    SvgSpriteVk,
    SvgSpriteWechat,
    SvgSpriteWhatsapp,
    SvgSpriteYoutube
  },

  props: {
    icon: {
      type: String,
      default: ''
    },

    url: {
      type: String,
      default: ''
    }
  },

  computed: {
    ROUNDINGS: () => ROUNDINGS
  }
}
</script>

<style lang="less" module>
@import (reference) "~@omnica/base/assets/stylesheets/variables.less";

.link {
  display: inline-flex;
  overflow: hidden;

  &_gmail,
  &_slack {
    background-color: #FFFFFF;
    box-shadow: inset 0 0 0 1px @grey-600;
  }

  &_roundings_none { border-radius: 0; }
  &_roundings_small { border-radius: 8px; }
  &_roundings_large { border-radius: 32px; }
}
</style>
